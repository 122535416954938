import { ListJobOfferSearchQuery } from "../../query/content/JobOfferListSearch";

export default function getJobOfferListSearch(
  langcode: String,
  query: String,
  filters: Array<Object>,
  limit: Number,
  offset: Number
) {
  return useAsyncQuery(ListJobOfferSearchQuery, {
    langcode,
    query,
    filters,
    limit,
    offset,
  });
}
