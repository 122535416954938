import { gql } from "graphql-tag";

export const ListJobOfferSearchQuery = gql`
  query ListJobOfferSearchQuery(
    $langcode: String!
    $query: String
    $filters: [InputOpenSearchConditionsGroup]
    $limit: Int
    $offset: Int
  ) {
    opensearchSearch(
      index: "curie"
      limit: $limit
      langcode: $langcode
      filters: $filters
      facets: [
        { field: "field_job_sector", limit: 1000 }
        { field: "field_job_contract", limit: 1000 }
        { field: "field_job_location", limit: 1000 }
        { field: "field_job_entity", limit: 1000 }
      ]
      query: $query
      offset: $offset
    ) {
      count
      documents {
        entity {
          id
          langcode
          entityBundle
          ... on NodeJobOffer {
            fieldTitle
            pathRawField {
              list {
                alias
              }
            }
            fieldJobContract {
              name
            }
            fieldJobLocation {
              name
            }
            fieldJobSector {
              name
            }
            fieldJobEntity {
              name
            }
          }
        }
      }
      facets {
        field
        values {
          field
          value
          count
          label: entity {
            ... on TaxonomyTermJobSector {
              value: name
            }
            ... on TaxonomyTermJobContract {
              value: name
            }
            ... on TaxonomyTermJobLocation {
              value: name
            }
            ... on TaxonomyTermJobEntity {
              value: name
            }
          }
        }
      }
    }
  }
`;
