<template>
  <div class="flex flex-col gap-2.5 overflow-hidden items-start w-full">
    <div
            v-if="displayTag === true"
            class="font-raleway font-bold rounded text-orangeCurie border border-orangeCurie text-[15px] lg:text-small py-1 px-1.5"
          >
            {{ $t("list.tag.publication") }}
    </div>
    <span
      v-if="publicationYear"
      class="font-bold font font-raleway text-[15px] lg:text-small text-orangeCurie"
      >{{ publicationYear }}</span
    >
    <UiCustomLink
      :path="item.entity.pathRawField.list[0].alias"
      customClass="flex m-0 font-raleway hover:text-orangeCurie font-bold text-[19px] lg:text-medium leading-[22px] lg:leading-[26px] underline-animation-border"
      tag="h3"
    >
      {{ item.entity.fieldTitle }}
    </UiCustomLink>
    <span class="italic font-raleway text-regularMobile lg:text-regular">{{
      item.entity.fieldPublicationNewspaper?.name
    }}</span>
    <div
      v-if="authors.length"
      @click="isDropdownOpen = !isDropdownOpen"
      class="font-raleway text-regular"
    >
      <UiCustomLink customClass="flex items-center gap-2.5 cursor-pointer italic text-regularMobile lg:text-regular">
        {{ $t("list.item.publication.showAuthors") }}
        <nuxt-icon
          :name="isDropdownOpen ? 'chevronUp' : 'chevronDown'"
          filled
          class="mt-1 mr-4 text-xl"
        />
      </UiCustomLink>
      <ul class="flex flex-wrap gap-1 mt-2" v-if="isDropdownOpen">
        <li class="font-raleway italic text-regularMobile lg:text-regular" v-for="(author,index) in authors" :key="index">
            {{ author }}  <span v-if="index != Object.keys(authors).length - 1">, </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OpenSearchDocument } from "@/interfaces/list";

const props = defineProps({
  item: {
    type: Object as PropType<OpenSearchDocument>,
    required: true,
  },
  displayTag: {
    type : Boolean,
    default: false
  }
});

const isDropdownOpen = ref(false);

const publicationYear = computed(() => {
  if (!props.item.entity.fieldDate?.value) {
    return null;
  }

  const date = new Date(props.item.entity.fieldDate.value);

  return date?.getFullYear();
});

const authors = computed(() => props.item.entity.fieldAuthor?.split(", ") ?? []);
</script>
