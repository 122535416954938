import { gql } from "graphql-tag";

export const ListSeminarSearchQuery = gql`
  query ListSeminarSearchQuery(
    $langcode: String!
    $query: String
    $filters: [InputOpenSearchConditionsGroup]
    $limit: Int
    $offset: Int
    $sort: InputSort
  ) {
    opensearchSearch(
      index: "curie"
      limit: $limit
      langcode: $langcode
      filters: $filters
      facets: [{ field: "field_type", limit: 1000 }]
      query: $query
      offset: $offset
      sort: $sort
    ) {
      count
      documents {
        entity {
          id
          langcode
          entityBundle
          ... on NodeSeminar {
            fieldType
            fieldTitle
            fieldDateBegin {
              value
            }
            fieldDateEnd {
              value
            }
            pathRawField {
              list {
                alias
              }
            }
            fieldSpeaker {
              fieldFirstName
              fieldLastName
            }
            fieldSite
            fieldRoom
          }
        }
      }
      facets {
        field
        values {
          field
          value
          count
          label: entity {
            ... on NodeSeminar {
              value: fieldType
            }
          }
        }
      }
    }
  }
`;
