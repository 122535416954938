<template>
  <UiCustomLink
    :path="item.entity.pathRawField.list[0].alias"
    class="w-full flex flex-row-reverse justify-end lg:justify-between gap-4 lg:gap-6 lg:flex-row"
    tag="li"
  >
    <div class="flex w-full justify-between lg:items-center flex-col gap-4 lg:flex-row">
      <div class="flex flex-col gap-2.5 lg:basis-[65%] overflow-hidden items-start">
        <div
          v-if="item.entity.fieldUnitType"
          class="font-raleway font-bold rounded text-orangeCurie border border-orangeCurie text-[15px] lg:text-small py-1 px-1.5"
        >
          {{ item.entity.fieldUnitType.name }}
        </div>
        <h3 class="w-fit hover:text-orangeCurie m-0 font font-raleway font-bold text-mobileTitleList lg:text-medium underline-animation-border">
          {{ item.entity.fieldTitle }}
        </h3>
        <div v-if="chiefs?.length">
          <p v-for="chief in chiefs.filter((chief) => chief.fieldPerson != null)" class="font-lora text-[22px] lg:text-h3Small leading-[25px]">
            {{ getChiefFullName(chief) }}
          </p>
        </div>
      </div>

      <div v-if="chiefs?.length" class="flex space-x-2">
        <div v-for="chief in chiefs.filter((chief) => chief.fieldPerson != null)" class="flex justify-end min-h-[94px] lg:min-h-[inherit] w-[94px]">
          <UiCustomImage
            v-if="chief.fieldPerson?.fieldImage?.entity.uriRawField.list[0].url"
            :src="chief.fieldPerson?.fieldImage?.entity.uriRawField.list[0].url"
            presets="unit_list"
            customClass="object-cover w-full rounded bg-grisClair h-fit min-h-[94px]"
          />
          <NuxtImg
              v-else
              class="object-cover w-full rounded bg-grisClair h-fit min-h-[94px]"
              presets="unit_list"
              src="/image/default.png"
              alt="Default"
          />
        </div>
      </div>
    </div>
  </UiCustomLink>
</template>

<script setup lang="ts">
import type { OpenSearchDocument, OpenSearchFieldTeamMember } from "@/interfaces/list";

const props = defineProps({
  item: {
    type: Object as PropType<OpenSearchDocument>,
    required: true,
  },
});

const chiefs = computed(() => {
  return props.item.entity.fieldTeamMember?.filter((member) => member.fieldChief === "1") ?? null;
});


const getChiefFullName = (chief: OpenSearchFieldTeamMember) => {
  const firstName = chief.fieldPerson?.fieldFirstName || "";
  const lastName = chief.fieldPerson?.fieldLastName || "";

  return `${firstName} ${lastName}`.trim();
};
</script>
