<template>
  <div class="flex justify-center items-center mt-14 lg:mt-20">
    <a
      @click.prevent="previousPage"
      :href="`?page=${currentPage - 1}`"
      :class="[
        'font-raleway text-regularMobile lg:text-regular hover:text-orangeCurie',
        { hidden: isPreviousDisabled },
      ]"
    >
      <span class="hidden lg:block">{{ $t("pagination.previous") }}</span>
      <span class="block lg:hidden"><</span>
    </a>
    <div class="flex gap-6 pr-10"
         :class="{'pl-10': !isPreviousDisabled}">
      
      <NuxtLink
        @click.prevent="goToPage(1)"
        rel="first"
        external=true
        :href="getPageLink(1)"
        :class="[
          'font-raleway text-regularMobile lg:text-regular hover:text-orangeCurie',
          {
            'font-bold text-orangeCurie pointer-events-none': currentPage === 1,
            '': currentPage !== 1,
          },
        ]"
      >
        1
    </NuxtLink>
    <span v-if="currentPage > 2 && totalPages>5">...</span>

      <a
        v-for="page in displayPages"
        :key="page"
        @click.prevent="goToPage(page)"
        :rel="
          currentPage - 1 === page
            ? 'prev'
            : currentPage + 1 === page
            ? 'next'
            : ''
        "
        :href="`?page=${page}`"
        :class="[
          'font-raleway text-regularMobile lg:text-regular hover:text-orangeCurie',
          {
            'font-bold text-orangeCurie pointer-events-none': currentPage === page,
            '': currentPage !== page,
          },
        ]"
      >
        {{ page }}
      </a>

      <span v-if="currentPage < (totalPages-2) && totalPages>5">...</span>
      <a
        @click.prevent="goToPage(totalPages)"
        rel="last"
        :href="`?page=${totalPages}`"
        :class="[
          'font-raleway text-regularMobile lg:text-regular hover:text-orangeCurie',
          {
            'font-bold text-orangeCurie pointer-events-none': currentPage === totalPages,
            '': currentPage !== totalPages,
          },
        ]"
      >
        {{ totalPages }}
    </a>
    </div>
        <a
      @click.prevent="nextPage"
      :href="`?page=${currentPage + 1}`"
      :class="[
        'font-raleway text-regularMobile lg:text-regular hover:text-orangeCurie',
        { hidden: isNextDisabled },
      ]"
    >
      <span class="hidden lg:block">{{ $t("pagination.next") }}</span>
      <span class="block lg:hidden"> > </span>
    </a>
    
  </div>
</template>

<script setup>
import useQuery from "~/composables/query";
const route = useRoute();
import { useRouteStore } from '~/stores/route';
const routeStore = useRouteStore();
const { updatePageQuery } = useQuery();
const { currentPage, totalPages } = storeToRefs(useList());

const isPreviousDisabled = computed(() => currentPage.value === 1);
const isNextDisabled = computed(() => currentPage.value === totalPages.value);


const getPageLink = (page) => {
  const queries = route.query;
  if(page ==1){
    delete queries.page;
  }
  return {path: routeStore.route.path, query: queries};
}

const displayPages = computed(() => {
  const result = [];
  if(totalPages.value > 5){
    if(currentPage.value >2) { result.push(currentPage.value-1);}
    if(currentPage.value > 1 && currentPage.value < totalPages.value) result.push(currentPage.value);
    if(currentPage.value+1 < totalPages.value) { result.push(currentPage.value+1);}
  }else{
    for(let page= 2; page < totalPages.value; page++){
      result.push(page);
    }
  }
  return result;
});

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    let list = document.querySelector("#list");
    list?.scrollIntoView();
    updatePageQuery(page);
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    let list = document.querySelector("#list");
    list?.scrollIntoView();
    updatePageQuery(currentPage.value + 1);
  }
};

const previousPage = () => {
  if (currentPage.value > 1) {
    let list = document.querySelector("#list");
    list?.scrollIntoView();
    updatePageQuery(currentPage.value - 1);
  }
};
</script>
