import { gql } from "graphql-tag";

export const ListUnitSearchQuery = gql`
  query ListUnitSearchQuery(
    $langcode: String!
    $query: String
    $filters: [InputOpenSearchConditionsGroup]
    $limit: Int
    $offset: Int
    $sort: InputSort
  ) {
    opensearchSearch(
      index: "curie"
      limit: $limit
      langcode: $langcode
      filters: $filters
      facets: [
        { field: "field_sites", limit: 1000}
        { field: "computed_team_chief_person", limit: 1000}
        { field: "field_scientific_axis", limit: 1000}
      ]
      query: $query
      offset: $offset
      sort: $sort
    ) {
      count
      documents {
        entity {
          id
          langcode
          entityBundle
          ... on NodeUnit {
            fieldTitle
            fieldUnitType {
              name
            }
            fieldTeamMember {
              fieldChief
              fieldPerson {
                id
                fieldFirstName
                fieldLastName
                fieldImage {
                  entity {
                    uriRawField {
                      list {
                        url
                      }
                    }
                  }
                }
              }
            }
            pathRawField {
              list {
                alias
              }
            }
          }
        }
      }
      facets {
        field
        values {
          field
          value
          count
          label: entity {
            ... on TaxonomyTermPartner {
              value: name
            }
            ... on NodePerson {
              value: title
            }
            ... on TaxonomyTermScientificAxis {
              value: name
            }
          }
        }
      }
    }
  }
`;
