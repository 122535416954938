import type { SortOrder } from "~/interfaces/list";
import { ListPersonSearchQuery } from "../../query/content/PersonListSearch";

export default function getPersonListSearch(
  langcode: String,
  query: String,
  filters: Array<Object>,
  limit: Number,
  offset: Number,
  sort?: SortOrder,
) {
  return useAsyncQuery(ListPersonSearchQuery, {
    langcode,
    query,
    filters,
    limit,
    offset,
    sort
  });
}
