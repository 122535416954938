import type { LocationQuery } from "vue-router";
import type { QueryValue } from "~/interfaces/list";

export default function useQuery() {
  const router = useRouter();
  const route = useRoute();

  const { setCurrentPage, setQuery, $reset } = useList();
  const { query, baseParameters } = storeToRefs(useList());

  const removeEmptyParameters = (query: Record<string, any>) => {
    return Object.fromEntries(
      Object.entries(query).filter(
        ([_, value]) => value && !(Array.isArray(value) && value.length === 0)
      )
    );
  };
  

  const reset = () => {
      $reset();
  };

  const pushQuery = async (query: Record<string, any>) => {
    const cleanedQuery = removeEmptyParameters(query);
   
   
    await navigateTo({
      path: route.path,
      query: cleanedQuery,
    });
    //fix bug vue router https://github.com/nuxt/nuxt/issues/20677
    if(cleanedQuery.page == 1){
      const queryParamsStr = Object.keys(route.query).map(key => `${key}=${route.query[key]}`).join("&");
      window.history.replaceState({}, '', `${route.path}${queryParamsStr ? '?'+queryParamsStr :""}`);
    }
  };

  const updatePageQuery = (page: number) => {
    pushQuery({ ...route.query, page });
  };

  const updateQuery = (query: Record<string, QueryValue>) => {
    pushQuery({ ...route.query, ...query, page: 1 });
  };

  const clearFacetsQuery = () => {
    const preservedQuery = Object.fromEntries(
      Object.entries(route.query).filter(([key]) => baseParameters.value.includes(key))
    );

    pushQuery(preservedQuery);
  };

  const searchWithQuery = (): void => updateQuery({ q: query.value });

  const handleQueryBaseParameters = (query: LocationQuery) => {
    if (query.page) {
      setCurrentPage(Number(query.page));
    }else{
      setCurrentPage(1);
    }

    if (query.q) {
      setQuery(query.q as string);
    }
  };

  const hasDates = computed(() => route.query.startDate && route.query.endDate);

  return {
    updateQuery,
    clearFacetsQuery,
    hasDates,
    updatePageQuery,
    searchWithQuery,
    handleQueryBaseParameters,
    reset
  };
}
