import { gql } from "graphql-tag";

export const ClinicalTrialListSearchQuery = gql`
  query ClinicalTrialListSearchQuery(
    $langcode: String!
    $query: String
    $filters: [InputOpenSearchConditionsGroup]
    $limit: Int
    $offset: Int
  ) {
    opensearchSearch(
      index: "curie"
      limit: $limit
      langcode: $langcode
      filters: $filters
      facets: [
        { field: "field_clinical_trial_location1", limit: 1000 }
        { field: "field_clinical_trial_target", limit: 1000 }
        { field: "field_clinical_trial_cancer_type", limit: 1000 }
        { field: "field_clinical_trial_phase", limit: 1000 }
        { field: "field_clinical_trial_organ", limit: 1000},
        { field: "field_clinical_trial_status", limit: 1000},
      ]
      query: $query
      offset: $offset
    ) {
      count
      documents {
        entity {
          id
          langcode
          entityBundle
          ... on NodeClinicalTrial {
            pathRawField {
              list {
                alias
              }
            }
            fieldSubtitle
            fieldClinicalTrialLocation1 {
              name
            }
            fieldClinicalTrialLocation2 {
              name
            }
            fieldTitle
            fieldIntroduction
            fieldPerson {
              fieldFirstName
              fieldLastName
            }
            fieldClinicalTrialCancerType {
              name
            }
          }
        }
      }
      facets {
        field
        values {
          field
          value
          count
          label: entity {
            ... on TaxonomyTermClinicalTrialLocation {
              value: name
            }
            ... on TaxonomyTermClinicalTrialTarget {
              value: name
            }
            ... on TaxonomyTermClinicalTrialCancerType {
              value: name
            }
            ... on TaxonomyTermClinicalTrialPhase {
              value: name
            }
            ... on TaxonomyTermClinicalTrialOrgan {
              value: name
            }
            ... on TaxonomyTermClinicalTrialStatus {
              value: name
            }
          }
        }
      }
    }
  }
`;
