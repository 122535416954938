import { gql } from "graphql-tag";

export const ListPublicationSearchQuery = gql`
  query ListPublicationSearchQuery(
    $langcode: String!
    $query: String
    $filters: [InputOpenSearchConditionsGroup]
    $limit: Int
    $offset: Int
    $sort: InputSort
  ) {
    opensearchSearch(
      index: "curie"
      limit: $limit
      langcode: $langcode
      filters: $filters
      facets: [
        { field: "computed_publication_team", limit: 1000 }
        { field: "computed_publication_platform", limit: 1000 },
        { field: "computed_publication_unit", limit: 1000 }
        { field: "field_publication_newspaper", limit: 1000 }
      ]
      query: $query
      offset: $offset
      sort: $sort
    ) {
      count
      documents {
        entity {
          id
          langcode
          entityBundle
          ... on NodePublication {
            fieldTitle
            fieldDate {
              value
            }
            pathRawField {
              list {
                alias
              }
            }
            fieldPublicationNewspaper {
              name
            }
            fieldAuthor
          }
        }
      }
      facets {
        field
        values {
          field
          value
          count
          label: entity {
            ... on NodeTeam {
              value: fieldTitle
            }
            ... on NodeUnit {
              value: fieldTitle
            }
            ... on TaxonomyTermPublicationNewspaper {
              value: name
            }
          }
        }
      }
    }
  }
`;
