<template>
  <div class="group flex flex-col-reverse gap-4 lg:gap-0 lg:flex-row items-start lg:justify-between w-full">
    <div class="flex flex-col gap-2 lg:gap-2.5 overflow-hidden items-start w-full lg:basis-[65%]">
      <div
          v-if="displayTag === true"
          class="font-raleway font-bold rounded text-orangeCurie border border-orangeCurie text-[15px] lg:text-small py-1 px-1.5"
      >
        {{ $t("list.tag.news") }}
      </div>
      <span class="font-lora font-500 text-orangeCurie text-regular leading-[26px] lg:leading-[22px]">{{
          item.entity.fieldSubtitle
        }}</span>
      <UiCustomLink :path="item.entity?.pathRawField?.list[0]?.alias">
        <h3
            class="font-lora text-medium lg:text-h3Small leading-[24px] mb-1.5 mt-0 underline-animation-border"
        >
          {{ item.entity.fieldTitle }}
        </h3>
      </UiCustomLink>
      <span v-if="date" class="text-small font-raleway mt-auto">{{ date }}</span>
    </div>
    <div v-if="item.entity.fieldImage?.entity.uriRawField.list.length" class="rounded w-[235px] overflow-hidden">
      <UiCustomImage
          :src="item.entity.fieldImage.entity.uriRawField.list[0].url"
          presets="team_actuality"
          customClass="object-cover w-full bg-grisClair transition-all ease delay-100 group-hover:scale-[1.2]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type {OpenSearchDocument} from "@/interfaces/list";

import {formatDate} from "@/utils/dateUtils";

const {t} = useI18n();
const props = defineProps({
  item: {
    type: Object as PropType<OpenSearchDocument>,
    required: true,
  },
  displayTag: {
    type: Boolean,
    default: false
  }
});

const date = computed(() => {
  return props.item.entity.fieldDate?.value ? formatDate(props.item.entity.fieldDate.value) : null;
});
</script>
