import { ClinicalTrialListSearchQuery } from "../../query/content/ClinicalTrialListSearch";

export default function getClinicalTrialListSearch(
  langcode: String,
  query: String,
  filters: Array<Object>,
  limit: Number,
  offset: Number
) {
  return useAsyncQuery(ClinicalTrialListSearchQuery, {
    langcode,
    query,
    filters,
    limit,
    offset,
  });
}
