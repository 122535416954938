import { gql } from "graphql-tag";

export const SearchQuery = gql`
query SearchQuery(
    $langcode: String!
    $query: String
    $filters: [InputOpenSearchConditionsGroup]
    $limit: Int
    $offset: Int
  ) {
    opensearchSearch(
      index: "curie"
      limit: $limit
      langcode: $langcode
      filters: $filters
      facets: [
      ]
      query: $query
      offset: $offset
    ) {
      count
      documents {
        entity {
          id
          langcode
          entityBundle
          ...team
          ...news
          ...contact
          ...cms
          ...clinical_trial
          ...card_cancer
          ...hub_cancer
          ...hub_don
          ...hub
          ...hub_treatment
          ...person
          ...publication
          ...seminar
          ...testimony
          ...unit
          
        }
      }
    }
  }

  fragment team on NodeTeam {
    fieldTitle
    fieldTeamType {
        name
    }
    pathRawField {
        list {
            alias
        }
    }

    fieldTeamMember {
        fieldChief
        fieldPerson {
            id
            fieldFirstName
            fieldLastName
            fieldImage {
                entity {
                    uriRawField {
                        list {
                            url
                        }
                    }
                }
            }
        }
    }
  }

  fragment news on NodeNews {
    id
    fieldTitle
    fieldSubtitle
    fieldDate {
        value
    }
    fieldImage {
        entity {
        uriRawField {
            list {
            url
            }
        }
        }
    }
    pathRawField {
        list {
        alias
        }
    }
  }

  fragment contact on NodeContact {
    fieldHeader {
        ...headerParagraph
    }
    pathRawField {
        list {
            alias
        }
    }
  }

  fragment cms on NodeCms {
    fieldHeader {
        ...headerParagraph
    }
    pathRawField {
        list {
            alias
        }
    }
  }

  fragment clinical_trial on NodeClinicalTrial {
    pathRawField {
        list {
        alias
        }
    }
    fieldSubtitle
    fieldClinicalTrialLocation1 {
        name
    }
    fieldClinicalTrialLocation2 {
        name
    }
    fieldTitle
    fieldIntroduction
    fieldPerson {
        fieldFirstName
        fieldLastName
    }
    fieldClinicalTrialCancerType {
        name
    }
  }

  fragment card_cancer on NodeCardCancer {
    fieldHubCancer {
        fieldHeaderRawField {
            list {
                entity {
                    ...headerParagraph
                }
            }
        }
    }
    fieldTitle
    pathRawField {
        list {
            alias
        }
    }
  }


  fragment hub_cancer on NodeHubCancer {
    fieldHeader {
        ...headerParagraph
    }
    pathRawField {
        list {
            alias
        }
    }
  }

  fragment hub_don on NodeHubDonation {
    fieldHeader2 {
        ...header2Paragraph
    }
    pathRawField {
        list {
            alias
        }
    }
  }

  fragment hub_treatment on NodeHubTreatment {
    fieldHeader2 {
        ...header2Paragraph
      }
      pathRawField {
        list {
            alias
        }
    }
  }

  fragment hub on NodeHub {
    fieldHeader2 {
        ...header2Paragraph
      }
      pathRawField {
        list {
            alias
        }
    }
  }


  fragment person on NodePerson {
    fieldFirstName
    fieldLastName
    fieldPersonTitle {
        name
    }
    fieldPersonSpeciality {
        name
    }
    fieldPersonLocation {
        name
    }
    fieldImage {
        alt
        entity {
        uriRawField {
            list {
            url
            }
        }
        }
    }
    pathRawField {
        list {
        alias
        }
    }
  }

  fragment publication on NodePublication {
    fieldTitle
    fieldDate {
        value
    }
    pathRawField {
        list {
        alias
        }
    }
    fieldPublicationNewspaper {
        name
    }
    fieldAuthor
  }

  fragment seminar on NodeSeminar {
    fieldType
    fieldTitle
    pathRawField {
        list {
            alias
        }
    }
    fieldDateBegin {
        value
    }
    fieldDateEnd {
        value
    }
    pathRawField {
        list {
        alias
        }
    }
    fieldSpeaker {
        fieldFirstName
        fieldLastName
    }
    fieldSite
    fieldRoom
  }

  fragment testimony on NodeTestimony{
    fieldTitle
    pathRawField {
        list {
            alias
        }
    }
  }

  fragment unit on NodeUnit{
    fieldTitle
    fieldUnitType {
        name
    }
    fieldTeamMember {
        fieldChief
        fieldPerson {
        id
        fieldFirstName
        fieldLastName
        fieldImage {
            entity {
            uriRawField {
                list {
                url
                }
            }
            }
        }
        }
    }
    pathRawField {
        list {
        alias
        }
    }
  }

  fragment headerParagraph on ParagraphHeader {
    id
    type: entityBundle
    fieldTitle
    fieldIntroduction2
    fieldImage {
      alt
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldTitlePush
    fieldTextPush: fieldBody
    fieldLinkPush: fieldLink {
      title
      uri {
        path
      }
    }
  }

  fragment header2Paragraph on ParagraphHeader2 {
    fieldTitle
    fieldImage {
      alt
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldImageMobile {
      alt
      entity {
        uriRawField {
          list {
            url
          }
        }
      }
    }
    fieldIntroduction2
    fieldText1
    fieldText2
    fieldPushText
    fieldPushLink {
      title
      uri {
        path
      }
    }
  }
`;
