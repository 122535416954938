<template>
  <div class="flex flex-col gap-2.5 overflow-hidden items-start w-full">
    <div class="flex flex-col lg:flex-row gap-2">
      <div
          v-if="displayTag === true"
          class="font-raleway font-bold rounded text-orangeCurie border border-orangeCurie text-[15px] lg:text-small py-1 px-1.5"
      >
        {{ $t("list.tag.clinical_trial") }}
      </div>
      <div v-if="item.entity.fieldClinicalTrialCancerType?.name"
           class="font-raleway font-bold rounded text-orangeCurie border border-orangeCurie text-small py-1 px-1.5"
      >
        {{ item.entity.fieldClinicalTrialCancerType?.name }}
      </div>
    </div>
    <div v-if="locations" class="flex items-center font-raleway text-small">
      <nuxt-icon name="map-alt" filled class="mr-1 text-regular flex items-center"/>
      {{ locations }}
    </div>
    <UiCustomLink
        :path="item.entity.pathRawField.list[0].alias"
        customClass="w-fit hover:text-orangeCurie m-0 font font-raleway font-bold text-h3Small lg:text-medium underline-animation-border"
        tag="h3"
    >
      {{ item.entity.fieldTitle }}
    </UiCustomLink>
    <div class="font-raleway text-regularMobile lg:text-regular leading-[25px]" v-html="item.entity.fieldIntroduction" />
    <p class="italic font-raleway text-regularMobile lg:text-regular leading-[25px]">
      {{ persons }}
    </p>
  </div>
</template>

<script setup lang="ts">
import type {OpenSearchDocument} from "@/interfaces/list";

const props = defineProps({
  item: {
    type: Object as PropType<OpenSearchDocument>,
    required: true,
  },
  displayTag: {
    type: Boolean,
    default: false
  }
});

const locations = computed(() => {
  return [
    props.item.entity.fieldClinicalTrialLocation1?.name,
    props.item.entity.fieldClinicalTrialLocation2?.name,
  ]
      .filter(Boolean)
      .join(", ");
});

const persons = computed(() => {
  return props.item.entity.fieldPerson
      ?.map(({fieldFirstName = "", fieldLastName = ""}) =>
          `${fieldFirstName} ${fieldLastName}`.trim()
      )
      .join(", ");
});
</script>
