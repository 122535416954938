<template>
  <div class="flex flex-col gap-2.5 items-start w-full lg:max-w-[65%]"
  :class="item.entity?.fieldImage?.entity?.uriRawField?.list?.length ? 'basis-[65%]' : ''"
  >
     <div
            v-if="displayTag === true"
            class="font-raleway font-bold rounded text-orangeCurie border border-orangeCurie text-[15px] lg:text-small py-1 px-1.5"
          >
            {{ $t("list.tag.person") }}
      </div>
    <UiCustomLink
      v-if="fullName"
      :path="item.entity?.pathRawField.list[0].alias"
      customClass="w-fit m-0 font font-lora text-medium lg:text-h3Small hover:text-orangeCurie underline-animation-border leading-[26px] lg:leading-4"
      tag="h3"
    >
      {{ fullName }}
    </UiCustomLink>
    <p
      v-if="item.entity?.fieldPersonTitle?.length"
      class="font-raleway text-regularMobile lg:text-regular leading-[25px]"
    >
      {{ item.entity?.fieldPersonTitle[0].name }}
    </p>
    <ul
      v-if="item.entity?.fieldPersonSpeciality?.length || item.entity?.fieldPersonSpecializedMedicin?.name"
      class="font-raleway lg:text-regular max-w-[100%] overflow-x-auto  flex gap-2 lg:flex-wrap items-center custom-scrollbar pb-2"
    >
      <li
        v-for="speciality in item.entity?.fieldPersonSpeciality"
        :key="speciality.name"
        class="border border-grisClair rounded-[100px] px-3 py-1 w-fit text-[15px] lg:text-small whitespace-nowrap"
      >
        {{ speciality.name }}
      </li>
      <li
        v-if="item.entity?.fieldPersonSpecializedMedicin?.name"
        class="border border-grisClair rounded-[100px] px-3 py-1 w-fit text-[15px] lg:text-small whitespace-nowrap"
      >
        {{ item.entity?.fieldPersonSpecializedMedicin.name }}
      </li>
    </ul>
    <div
      v-if="item.entity?.fieldPersonLocation?.name"
      class="flex align-center font-raleway text-small"
    >
      <nuxt-icon name="map-alt" filled class="mr-1 text-regular flex items-center" />
      {{ item.entity?.fieldPersonLocation.name }}
    </div>
  </div>
  <div
    class="lg:ml-auto w-[110px] lg:w-[150px]"
  >
    <UiCustomImage
      v-if="item.entity?.fieldImage?.entity?.uriRawField?.list?.length"
      :src="item.entity?.fieldImage?.entity?.uriRawField.list[0].url"
      preset="person_list"
      customClass="object-cover w-full rounded bg-grisClair"
    />
    <NuxtImg
        v-else
        class="w-full rounded rounded-1"
        src="/image/default.png"
        presets="person_side"
        alt="Default"
    />
  </div>
</template>

<script setup lang="ts">
import type { OpenSearchDocument } from "@/interfaces/list";

const props = defineProps({
  item: {
    type: Object as PropType<OpenSearchDocument>,
    required: true,
  },
  displayTag: {
    type : Boolean,
    default: false
  }
});

const fullName = computed(() => {
  const firstName = props.item.entity?.fieldFirstName || "";
  const lastName = props.item.entity?.fieldLastName || "";

  return `${firstName} ${lastName}`.trim();
});
</script>

<style>
.custom-scrollbar {
  /* custom scrollbar */
  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #D7D7D7;
  }
}
</style>