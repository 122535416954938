<template>
  <div id="list">
    <div class="pb-2 font-raleway text-[15px] lg:pb-4">
      <p>{{ countResultLabel ? countResultLabel : $t("list.items.results", { count: searchData.count }) }}</p>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-row gap-4 justify-end lg:justify-between lg:gap-6 lg:flex-row">
        <ul class="flex flex-col w-full" v-if="searchData.documents.length > 0">
          <li v-for="item in searchData.documents">
            <div v-if="item.entity?.id" class="flex flex-col-reverse justify-end lg:justify-between gap-4 lg:gap-6 lg:flex-row py-4 relative">
              <component :is="dynamicComponent(item)" :item="item" :displayTag="displayTag" />
            </div>
          </li>
        </ul>
      </div>
    </div>

    <ListPagination v-if="totalPages > 1" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  list: {
    type: String,
    required: false,
  },
  countResultLabel:{
    type: String,
    required: false,
  },
  displayTag:{
    type: Boolean,
    required: false,
    default: false
  }
});

const { searchData, totalPages } = storeToRefs(useList());


const itemComponents: Record<string, ReturnType<typeof resolveComponent>> = {
  news: resolveComponent("NewsItem"),
  job: resolveComponent("JobItem"),
  team: resolveComponent("TeamItem"),
  person: resolveComponent("PersonItem"),
  unit: resolveComponent("UnitItem"),
  publication: resolveComponent("PublicationItem"),
  clinical_trial: resolveComponent("ClinicalTrialItem"),
  seminar: resolveComponent("SeminarItem"),
  card_cancer: resolveComponent("CardCancerItem"),
  testimony: resolveComponent("TestimonyItem"),
  hub_treatment: resolveComponent("HubItem"),
  hub: resolveComponent("HubItem"),
  hub_donation: resolveComponent("HubItem"),
  cms: resolveComponent("CmsItem"),
  contact: resolveComponent("contactItem")

};

const dynamicComponent = (item) => { 
  const component = itemComponents.news;
  if(props.list && itemComponents[props.list]){
    return itemComponents[props.list];
  }
  if(itemComponents[item.entity.entityBundle]){
    return itemComponents[item.entity.entityBundle];
  }
  return component;
}
</script>
