<template>
  <div class="flex flex-col gap-2.5 overflow-hidden items-start w-full">
    <div
      v-if="displayTag === true"
      class="font-raleway font-bold rounded text-orangeCurie border border-orangeCurie text-[15px] lg:text-small py-1 px-1.5"
    >
      {{ $t("list.tag.seminary") }}
    </div>
    <span class="w-fit rounded text-orangeCurie font-lora text-[15px] lg:text-regular py-0.5">
      {{ item.entity.fieldType }}
    </span>
    <UiCustomLink
      :path="item.entity.pathRawField.list[0].alias"
      customClass="m-0 font font-lora leading-[25px] text-medium lg:text-h3Small hover:text-orangeCurie underline-animation-border -mt-1"
      tag="h3"
    >
      {{ item.entity.fieldTitle }}
    </UiCustomLink>
    <p v-if="speakers" class="font-raleway text-regularMobile lg:text-regular leading-[25px] -mt-1">
      {{ $t("list.item.seminar.speakers") }} : {{ speakers }}
    </p>
    <div v-if="dateRange" class="font-raleway text-small leading-[18px] mt-4 lg:mt-[30px]">
      {{ dateRange }}
    </div>
    <div v-if="location" class="flex align-center font-raleway text-small leading-[18px]">
      <nuxt-icon name="map-alt" filled class="mr-1 text-regular flex items-center" />
      {{ location }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatDateWithTime, formatTime, isSameDay } from "@/utils/dateUtils";

import type { OpenSearchDocument, OpenSearchFieldSeminarPerson } from "@/interfaces/list";

const props = defineProps({
  item: {
    type: Object as PropType<OpenSearchDocument>,
    required: true,
  },
  displayTag: {
    type: Boolean,
    default: false,
  },
});

const formatSpeakers = (speakers: OpenSearchFieldSeminarPerson[]) => {
  return speakers
    ?.map(({ fieldFirstName = "", fieldLastName = "" }) => [fieldFirstName, fieldLastName].filter(Boolean).join(" "))
    .join(", ");
};

const speakers = computed(() => formatSpeakers(props.item.entity.fieldSpeaker || []));

const dateRange = computed(() => {
  const { fieldDateBegin, fieldDateEnd } = props.item.entity;
  if (!fieldDateBegin?.value || !fieldDateEnd?.value) return false;

  return formatDateRange(fieldDateBegin.value, fieldDateEnd.value);
});

const formatDateRange = (beginString: string, endString: string) => {
  const beginDate = new Date(beginString);
  const endDate = new Date(endString);

  if (isSameDay(beginDate, endDate)) {
    return formatSingleDayEvent(beginString, endString);
  } else {
    return formatMultiDayEvent(beginString, endString);
  }
};

const formatSingleDayEvent = (beginString: string, endString: string) => {
  return `${formatDateWithTime(beginString)} - ${formatTime(endString)}`;
};

const formatMultiDayEvent = (beginString: string, endString: string) => {
  return `Du ${formatDateWithTime(beginString)} au ${formatDateWithTime(endString)}`;
};

const location = computed(() => {
  const { fieldSite, fieldRoom } = props.item.entity;

  if (fieldSite && fieldRoom) {
    return `${fieldSite} - ${fieldRoom}`;
  } else {
    return fieldSite || fieldRoom || "";
  }
});
</script>
