import { SearchQuery } from "../../query/search/Search";

export default function getSearch(
    langcode: String,
    query: String,
    filters: Array<Object>,
    limit: Number,
    offset: Number
  ) {
    return useAsyncQuery(SearchQuery, {
      langcode,
      query,
      filters,
      limit,
      offset,
    });
  }